.maplibregl-ctrl button.maplibregl-ctrl-geolocate .maplibregl-ctrl-icon
{
  position: absolute;
  bottom: 6px;
  font-size: 10px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  color: #000000;
  margin: auto;
  left: 0;
  right: 0;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIfSURBVHgB7dzrTeQwGIXho9UWQAkuYUtwCdsBQwVQAh1AB0AFQAUJFQAVDB1ABxBruAQx32AnTsZJ3kc6EoL5g2V9J9eRAAAAAAAAAAAAgFQHTU41sD9aNt/kvsk/YTBnTV7fsxKyc00qfS1yCDs6s+Mmz/q+yGshm1B4F/q+wB+5EbLw2uzaVyMnQm/twrPCfO7BaXPY9tsiPwudbSs8K5WQLBTeteIWmPnckdfuwrPihWgxhWcFEZziCo/53ENK4Vk5FUxdCo/5nMirW+FZORB+6FN4Rcznvyqb02ZU5D5NfhQ+5Sg8K/+FrIXHfDZ45S28bbnXgoUdlrvwrFxooZz6neExnyMMWXhWnBYkjIpK4y5wyFoL4jV84S16Po9ZeFZWmrlwZjdm4VmZ9Y3YfRTeouaz034Kz8peH5QZ6qKS1+Y0uqRT3VozUkLhzX4+h39krTIXee8PyuQeHUca3qrJodLcCcm80nc0D8p0lHq46IVOzjWh+RxM9WWhlGPiBxVgqgtdN3mK/GwRRTjl19+uIj9XC704xc1oZFBp9yJXKsTU35y9/eXvPCiTSbi2wvHzSHaNj2KuHs7hpXtrfITj5xchG2t8nKsgc9jRYdfWW35fC9mt9HNHOyG7MD7aV/SKe5BxLt9AE8ZHuxSLuJDUNqev+rls/cwdlYF9jA8nDCoc0q1VoNJfFkoVbgg4YRS8CAQAAAAAAAAA5XkDUojOvLeaoLQAAAAASUVORK5CYII=")!important;
  background-size: 27px;
  background-position: 10px 8px;
}

.maplibregl-ctrl button.maplibregl-ctrl-geolocate .maplibregl-ctrl-icon::after
{
  content: "現在地";
}

.maplibregl-ctrl button.maplibregl-ctrl-geolocate.is-geolocating .maplibregl-ctrl-icon::after
{
  content: "取得中";
  animation: geolocating-flash 2s linear infinite;
}

@keyframes geolocating-flash {
  0%,100% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }
}

.maplibregl-ctrl button.maplibregl-ctrl-geolocate.is-geolocate-error .maplibregl-ctrl-icon::after
{
  content: "エラー";
  color: #ff0000;
}

.maplibregl-ctrl.maplibregl-ctrl-group:has(.maplibregl-ctrl-geolocate) button.is-geolocate-error{
  background: #fff7f7;
}

.maplibregl-ctrl-group button
{
  width: 46px!important;
  height: 46px!important;
}

.maplibregl-ctrl.maplibregl-ctrl-group {
  border-radius: 46px;
}

.maplibregl-ctrl.maplibregl-ctrl-group:has(.maplibregl-ctrl-geolocate)
{
  box-shadow: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 46px;
}

.maplibregl-ctrl-bottom-right {
  & :nth-child(2) {
    position: relative;
    right: 10px;
  }
}

.user-position-marker {
  width: 20px;
  height: 20px;
  background: #FF0000;
  border: 4px solid #FFFFFF;
  box-shadow: 0px 4.83208px 4.83208px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}

.zoomInAlert {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 150px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 40%);
  padding: 10px;
  width: 270px;
  display: block;
  font-size: 12px;
  line-height: 1.3;
  text-align: center;
  transition: .5s;
  opacity: 1;
  pointer-events: none;

  .divider {
    height: 1px;
    width: 20px;
    display: block;
    margin: 15px auto;
    background-color: #ffffff;
  }
}

.zoomInAlert.hide {
  opacity: 0;
}

.maplibregl-canvas-container {
  width: 100%;
  height: 100%;
  canvas {
    width: 100%;
    height: 100%;
  }
}


hr {
  margin: 10px 0;
  border: 0;
  border-top: 1px solid #fff;
}
.container {
    display: flex;
    width: 100%;

  .notFound {
    text-align: left;
    padding: 26px;
    color: #2B2B2B;
    max-width: 1120px;
    margin: auto;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    h2 {
      font-size: 18px;
      margin-bottom: 15px;
    }

    div {
      max-width: 1100px;
    }
  }
}

.app-header {
  height: 62px;

  .title {
    font-size: 16px;
    font-weight: bold;
  }

  .brand {
    position: relative;
    z-index: 2;
    background-color: #ffffff;
    width: 100%;
    height: 100%;

    .brand-content {

      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      height: 100%;

      img {
        width: 35px;
        height: 35px;
      }

      * {
        margin: 0 5px;
      }
    }
  }

  #guide-panel {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: calc(-100% + 62px);

    #guide-panel-check {
      display: none;
    }

    .guide-panel-content {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: #F8F8F8;
      text-align: center;
      transition: .5s;

      .guide-panel-button {
        position: absolute;
        bottom: -19px;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #F8F8F8;
        width: 100%;
        height: 20px;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

        .arrow {
          border: solid black;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 5px;
          margin-bottom: 7px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transition: .5s;
        }
      }

      p {
        position: absolute;
        bottom: 13px;
        right: 0;
        left: 0;
        font-size: 19px;
        font-weight: 700;
        line-height: 23px;
        width: 280px;
        margin: auto;
      }
    }
  }

  #guide-panel-check:checked~.guide-panel-content {
    top: 78px;
    .arrow {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
  }

  .drawer {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .drawer-hidden {
    display: none;
  }

  .drawer-open {
    display: flex;
    height: 60px;
    width: 60px;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 100;
    cursor: pointer;
  }

  .drawer-open span,
  .drawer-open span:before,
  .drawer-open span:after {
    content: '';
    display: block;
    height: 3px;
    width: 25px;
    border-radius: 3px;
    background: #333;
    transition: 0.5s;
    position: absolute;
  }

  .drawer-open span:before {
    bottom: 8px;
  }

  .drawer-open span:after {
    top: 8px;
  }

  #drawer-check:checked~.drawer-open span {
    background: rgba(255, 255, 255, 0);
  }

  #drawer-check:checked~.drawer-open span::before {
    bottom: 0;
    transform: rotate(45deg);
  }

  #drawer-check:checked~.drawer-open span::after {
    top: 0;
    transform: rotate(-45deg);
  }

  .drawer-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 99;
    background: #EDEDED;
    transition: .5s;

    .drawer-title {
      font-size: 19px;
      font-weight: 400;
      text-align: center;
      height: 62px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 23px;
    }

    .drawer-body {
      height: calc(100% - 94px);
      overflow-y: auto;
      padding: 32px 25px;

      .about-app {
        margin: 28px 0;
      }

      .drawer-list {
        list-style: none;
        max-width: 300px;

        .drawer-item {

          margin: 23px 0;
          line-height: 19px;

          a {
            color: #000000;
            text-decoration: underline;
            font-size: 14px;
          }
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: #DDDDDD;
      }

      .drawer-body-brand {
        margin: 21px 8px;
        display: flex;
        align-items: center;

        img {
          height: 40px;
          margin-right: 10px;
        }
        h1 {
          font-size: 20px;
          font-weight: 700;
          line-height: 29px;
          color: #000000;
        }
      }

      .share {
        margin: 0 8px;
      }

      .release-date {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
        margin: 21px 8px;
      }

      details {
        margin: 21px 8px 60px 8px;
        summary {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: #000000;
          cursor: pointer;
        }
        h2 {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: #000000;
          margin: 10px;
          margin-top: 15px;

        }
        p, li, a {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          margin: 10px
        }
        li {
          list-style-position: inside;
          padding-left: 1em;
          text-indent: -1em;
        }
      }
    }
  }

  #drawer-check:checked~.drawer-content {
    left: 0;
  }
}

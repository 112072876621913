.ios-a2hs-popover {
  width: 220px;
  position: absolute;
  bottom: 11px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px 20px 10px 10px;
  border-radius: 10px;
  background: #d9e8f7;
  border: 3px solid #fff;
  color: #000;
  z-index: 2;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
  p {
    font-size: 12px;
    line-height: 1.3;
    span{
      font-size: 13px;
      font-weight: bold;
      color: #3278BE;
    }
  }  
  .ios-a2hs-popover__close-button {
    position: absolute;
    top: -6px;
    right: -2px;
    padding: 5px;
    cursor: pointer;
    border: none;
    background-color: transparent;

    .ios-a2hs-popover__close-icon {
      width: 15px;
      height: 15px;
      fill: #222;
    }
  }
  
}

.ios-a2hs-popover:after {
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  bottom: -24px;
  margin-left: calc(50% - 10px);
  border: solid transparent;
  border-color: rgba(51, 204, 153, 0);
  border-top-color: #fff;
  border-width: 12px;
  pointer-events: none;
  z-index: 2;
  content: " ";
}
.ios-a2hs-popover__content{
  position: relative;
  padding-left: 45px;
}
.ios-a2hs-popover__add-logo{
  position: absolute;
  top: 0px;
  left: -5px;
  padding: 5px;
  background:#fff;
  width: 30px;
  height: 30px;
  border: 1px solid #c7d7e8;
  border-radius: 8px;
  .ios-a2hs-popover__add-logo-img{
    width: 30px;
  }
}
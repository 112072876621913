.takamatsu-popup {

  max-height: calc(100% - 82px);

  .maplibregl-popup-tip {
    border-top-color: #fff;
    // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .maplibregl-popup-close-button {
    width: 24px;
    height: 24px;
    color: #6f6f6f;
    font-size: 24px;
    line-height: 1;
    top: 4px;
    right: 8px;
  }

  .maplibregl-popup-content {
    width: 85vw;
    height: auto;
    max-width: 327px;
    max-height: 450px;
    background-color: #fff;
    padding: 24px 16px 16px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    overflow-y: auto;
    border-radius: 8px;
    box-sizing: border-box;

    .popup-container {

      .popup-loading {
        margin: 50px auto;
      }

      .popup-description {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 23px;
      }

      .popup-cta-container {

        display: flex;
        justify-content: center;
        margin: 30px 0;

        .popup-cta-button {
          font-weight: 700;
          font-size: 13px;
          line-height: 16px;
          color: #ffffff;
          background: #4B4B4B;
          border-radius: 20px;
          padding: 8px 28px 6px;
          pointer-events: auto;
        }
      }

      .warning-card {
        color: #ff0000;
        padding: 10px;
        font-size: 13px;
        font-weight: bold;
        border: 1px solid;
        line-height: 16px;
      }

      .popup-section-title {
        font-size: 20px;
        margin: 8px 0;
      }
      .popup-section-title + .popup-section-container-facility{
        margin-top: 20px;
      }
      .popup-section-title.no-data {
        padding: 16px 10px;
        margin-bottom: 20px;
        background: #f2f9ff;
        border: 1px solid rgba(1,85,145,0.15);
        font-size: 15px;
        line-height: 1.4;
        color: #015591;
        font-weight: bold;
      }

      .popup-section-container {
        margin: 12px 0 28px;
        border-top: 2px solid #aaa;
        border-bottom: 2px solid #aaa;
        padding: 5px 0;

        .popup-section.is-empty {
          width: 100%;

          .popup-text {
            width: 100%;
            text-align: center;
          }
        }

        .popup-section {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 8px 0;
          padding-right: 8px;

          .popup-icon {
            width: 50px;
            height: 55px;
            min-width: 50px;
            border: 1px solid #000000;
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            border-radius: 12px;
            position: relative;
            margin-right: 12px;

            .popup-icon-inner {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              margin: auto;
              height: 41px;
              width: 48px;
              text-align: center;
              display: flex;
              flex-direction: column;

              img {
                height: 28px;
              }

              p {
                font-size: 9px;
                font-weight: 400;
                line-height: 1;
                margin-top: 3px;
              }
            }
          }

          .popup-text {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .popup-text-title {
              font-size: 16px;
              font-weight: 700;
              line-height: 21px;
            }

            .popup-text-altitude {
              font-size: 12px;
              line-height: 1;
              margin-top: 5px;
            }

            a {
              pointer-events: auto;
              font-size: 13px;
              color: #333333;
              font-weight: 400;
              text-decoration: underline;
              line-height: 23px;
            }
          }
        }

        .popup-text-altitude {
          font-size: 14px;
          text-align: right;
          margin-top: -10px;
        }
      }

      .popup-section + .popup-section {
        border-top: 1px solid #ddd;
        padding-top: 8px;
      }

      .popup-section-container-facility {

        margin-bottom: 20px;

        .popup-section-facility-title {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          img {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
          div {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
          }
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 6px;
            border: 1px solid #DEDEDE;
            padding: 6px 22px 6px 6px;
            border-radius: 4px;
            position: relative;
            cursor: pointer;
            .label {
              font-size: 14px;
              margin-right: 30px;
            }
            .distance {
              font-size: 11px;
            }
            &::after{
              content: '';
              width: 6px;
              height: 6px;
              border-top: solid 1px #bbb;
              border-right: solid 1px #bbb;
              position: absolute;
              right: 8px;
              top: 0px;
              bottom: 2px;
              margin: auto;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}

.takamatsu-popup.hazard {
  .maplibregl-popup-content {
    .popup-container {
      .popup-loading {
        margin: 85px auto 50px auto;
      }
    }
  }
}

.takamatsu-popup.facility {

  .maplibregl-popup-content {
    .popup-container {

      .popup-loading {
        margin: 85px auto;
      }

      .popup-text-title {
        font-size: 20px;
        line-height: 1.2;
      }

      .popup-text-label{
        display: flex;
        margin-top: -12px;
        margin-bottom: 8px;
      }
      .popup-text-icon {
        height: 18px;
        margin-right: 4px;
      }

      .popup-text-category {
        font-size: 14px;
      }

      .popup-text-meta{
        background: #f5f5f5;
        font-size: 12px;
        line-height: 1;
        margin-top: 5px;
        padding: 8px;

      }

      .popup-text-content {
        font-size: 14px;
        line-height: 25px;
      }
      .popup-text-content-address{
        font-size: 16px;
        margin: 8px 0;
      }

      table {
        margin: 12px 0;
        width: 100%;
        border-collapse:collapse;

        tr {
          th {
            width: 85px;
            font-weight: normal;
            padding: 8px 4px 4px;
            border-top: 1px solid #ddd;
            text-align: left;
            background: #f4f4f4;
            font-size: 12px;
            color: #666;
            line-height: 1.3;
          }
          td {
            width: auto;
            padding: 4px 8px;
            border-top: 1px solid #ddd;
            font-size: 14px;
            line-height: 1.3;
          }

          img {
            width: 24px;
            height: auto;
            vertical-align: middle;
          }
        }
      }
      tr:last-child{
        th,td{
          border-bottom: 1px solid #cdcdcd;
        }
      }

      .note {
        font-size: 12px;
      }
      .table-shelter-meta{
        th{
          padding: 4px;
        }
      }
      .table-shelter{
        td:first-child {
          width: 40px;
          padding: 8px;
          border-bottom: 1px solid #caebdd;
        }

        td:nth-child(2) {
          width: auto;
          font-size: 15px;
          font-weight: 600;
          padding: 8px;
          border-bottom: 1px solid #caebdd;
        }

        td:nth-child(3) {
          font-size: 18px;
          width: 2em;
          text-align: center;
          padding: 8px;
          border-bottom: 1px solid #caebdd;
        }
        td.last-child {
          border-bottom: 1px solid #cdcdcd;
        }
      }
    }
  }
}

// カテゴリによって色を変える
.takamatsu-popup.facility.shelter,
.takamatsu-popup.facility.EvacuationShelter,
.takamatsu-popup.facility.TemporaryEvacuationShelter {
  .maplibregl-popup-tip {
    border-top-color: #00A560;
  }
  .maplibregl-popup-content {
    border: 5px solid #00A560;
  }
  .maplibregl-popup-content .popup-container .popup-text-title{
    color:#00A560;
  }
  .maplibregl-popup-content .popup-container table.table-shelter{
    border-top: 2px solid #00A560;
    border-bottom: 2px solid #00A560;
    margin-bottom: 4px;
  }
}

.takamatsu-popup.facility.aed {
  .maplibregl-popup-tip {
    border-top-color: #A50000;
  }
  .maplibregl-popup-content {
    border: 5px solid #A50000;
  }
  .maplibregl-popup-content .popup-container .popup-text-title{
    color:#A50000;
  }
}

.takamatsu-popup.facility.hospital {
  .maplibregl-popup-tip {
    border-top-color: #455DB7;
  }
  .maplibregl-popup-content {
    border: 5px solid #455DB7;
  }
  .maplibregl-popup-content .popup-container .popup-text-title{
    color:#455DB7;
  }
}

.takamatsu-popup.facility.fire-station {
  .maplibregl-popup-tip {
    border-top-color: #FF0000;
  }
  .maplibregl-popup-content {
    border: 5px solid #FF0000;
  }
  .maplibregl-popup-content .popup-container .popup-text-title{
    color:#FF0000;
  }
}

.takamatsu-popup.facility.WaterLevel,
.takamatsu-popup.facility.PrefectureWaterLevel,
.takamatsu-popup.facility.FloodSituation-blue,
.takamatsu-popup.facility.Rainfall,
.takamatsu-popup.facility.TideLevel,
.takamatsu-popup.facility.PrefectureTideLevel {
  .maplibregl-popup-tip {
    border-top-color:  #008FDF;
  }
  .maplibregl-popup-content {
    border: 5px solid  #008FDF;
  }
  .maplibregl-popup-content .popup-container .popup-text-title {
    color: #008FDF;
  }
}

.takamatsu-popup.facility.FloodSituation, .takamatsu-popup.facility.chikadou_custom_point {
  .maplibregl-popup-tip {
    border-top-color:  #FFF000;
  }
  .maplibregl-popup-content {
    border: 5px solid  #FFF000;
  }
}


// 危険度高
.takamatsu-popup.facility.TideLevel-purple,
.takamatsu-popup.facility.PrefectureTideLevel-purple,
.takamatsu-popup.facility.WaterLevel-purple,
.takamatsu-popup.facility.PrefectureWaterLevel-purple  {
  .maplibregl-popup-tip {
    border-top-color: #9747ff;
  }
  .maplibregl-popup-content {
    border: 5px solid #9747ff;
  }
  .maplibregl-popup-content .popup-container .popup-text {
    color:#9747ff;
  }
}
// 危険度中
.takamatsu-popup.facility.TideLevel-red,
.takamatsu-popup.facility.PrefectureTideLevel-red,
.takamatsu-popup.facility.FloodSituation-red,
.takamatsu-popup.facility.WaterLevel-red,
.takamatsu-popup.facility.PrefectureWaterLevel-red  {
  .maplibregl-popup-tip {
    border-top-color:  #ff2020;
  }
  .maplibregl-popup-content {
    border: 5px solid  #ff2020;
  }
  .maplibregl-popup-content .popup-container .popup-text {
    color: #ff2020;
  }
}
// 危険度低
.takamatsu-popup.facility.TideLevel-yellow,
.takamatsu-popup.facility.PrefectureTideLevel-yellow,
.takamatsu-popup.facility.WaterLevel-yellow,
.takamatsu-popup.facility.PrefectureWaterLevel-yellow  {
  .maplibregl-popup-tip {
    border-top-color: #d8b404;
  }
  .maplibregl-popup-content {
    border: 5px solid #d8b404;
  }
  .maplibregl-popup-content .popup-container .popup-text {
    color: #d8b404;
  }
}
// データなし
.takamatsu-popup.facility.TideLevel-gray,
.takamatsu-popup.facility.PrefectureTideLevel-gray,
.takamatsu-popup.facility.WaterLevel-gray,
.takamatsu-popup.facility.PrefectureWaterLevel-gray  {
  .maplibregl-popup-tip {
    border-top-color:  #c9c9c9;
  }
  .maplibregl-popup-content {
    border: 5px solid  #c9c9c9;
  }
  .maplibregl-popup-content .popup-container .popup-text {
    color: #c9c9c9;
  }
}
